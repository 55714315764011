.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('./BG.png');
  background-size: cover;
  background-position: center;
  color: white;
}

header {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem 0;
}

nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

nav a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

main {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}